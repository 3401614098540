<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Region"
          >
            <form-v-select
              ref="region"
              v-model="region"
              dataurl="region"
              label=""
              placeholder="Semua Region"
              :item_text="'name'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Jabatan"
          >
            <form-v-select
              ref="job_title"
              v-model="job_title"
              dataurl="job-title"
              label=""
              placeholder="Semua Jabatan"
              :item_text="'name'"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="approval_status"
              :options="[
                { value: null, text: 'Semua' },
                { value: '0', text: 'Pengajuan' },
                { value: '1', text: 'Terdaftar' },
                { value: '2', text: 'Ditolak' },
              ]"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Region"
          >
            <baseSelect
              v-model="region"
              dataurl="/region"
              title="Region"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(approval_status)="data">
          <span
            class="badge "
            :class="data.item.approval_status == 1?'bg-primary':data.item.approval_status == 0?'bg-warning':'bg-danger'"
          >
            {{ data.item.approval_status == 1?'Diterima':data.item.approval_status == 0?'Pengajuan':'Ditolak' }}
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol } from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    baseSelect,
    BFormSelect,
    BRow,
    BCol,
    FormVSelect
  },
  data() {
    return {
      dataurl:"/staff",
      baseroute:"staff",
      title:"Pegawai",
      fields: [
        { key: 'region.name', label: 'region', sortable: true },
        // { key: 'code', label: 'code', sortable: true },
        { key: 'name', label: 'Nama Pegawai', sortable: true },
        { key: 'job_title.name', label: 'Jabatan', sortable: true },
        { key: 'user.role.name', label: 'Role', sortable: true },
        { key: 'approval_status', label: 'Status', sortable: true }
      ],
      showFilter : false,
      filter :[],
      region:null,
      job_title:null,
      approval_status:null,
    }
  },
  methods:{
    show(){
      this.filter['filter[job_title_id]']=this.job_title?this.job_title.id:null
      this.filter['filter[branch_id]']=this.region?this.region.id:null
      this.filter['filter[approval_status]']=this.approval_status

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.region = null
      this.job_title = null
      this.approval_status = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
